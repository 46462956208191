import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Link,
  Box,
  Section,
  Strong,
  Icon,
  Image,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { IoIosCheckmarkCircle } from "react-icons/io"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Accueil | Sovixel Rentals</title>
        <meta
          name={"description"}
          content={"Là où l'aventure commence sur deux roues"}
        />
        <meta property={"og:title"} content={"Accueil | Sovixel Rentals"} />
        <meta
          property={"og:description"}
          content={"Là où l'aventure commence sur deux roues"}
        />
        <meta
          property={"og:image"}
          content={"https://sovixel.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://sovixel.com/img/2107174.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://sovixel.com/img/2107174.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://sovixel.com/img/2107174.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://sovixel.com/img/2107174.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://sovixel.com/img/2107174.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://sovixel.com/img/2107174.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="linear-gradient(0deg,rgba(255, 255, 255, 0.6) 0%,rgba(255, 255, 255, 0.6) 100%),url(https://sovixel.com/img/1.jpg) center center/cover no-repeat"
        min-height="100vh"
        lg-padding="140px 0 88px 0"
        quarkly-title="Hero-21"
        padding="88px 0 88px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          flex-wrap="wrap"
          align-items="center"
          justify-content="center"
        />
        <Text
          letter-spacing="2px"
          margin="0px 0px 16px 0px"
          color="--greyD2"
          font="--base"
          lg-width="100%"
          lg-text-align="center"
          text-align="center"
          text-transform="uppercase"
        >
          Bienvenue à Sovixel!
        </Text>
        <Text
          color="--darkL1"
          font="--headline1"
          sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          margin="0px 0px 42px 0px"
          lg-width="100%"
          md-width="100%"
          lg-text-align="center"
          lg-margin="0px 0px 20px 0px"
          lg-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          text-align="center"
          letter-spacing="30px"
        >
          Sovixel Rentals
        </Text>
        <Text
          lg-text-align="center"
          text-align="center"
          margin="0px 0px 36px 0px"
          color="--darkL2"
          font="--lead"
          width="100%"
        >
          Bienvenue aux aventuriers et aux amateurs de sensations fortes ! Chez Sovixel Rentals, nous pensons que tout voyage commence par une belle balade. Que vous rouliez dans les rues de la ville ou que vous exploriez des paysages accidentés, nos motos de premier ordre sont votre compagnon fiable pour des expériences inoubliables. Plongez dans le grand frisson de la conduite avec nous - c'est là que votre aventure commence.
        </Text>
        <Box display="flex" sm-flex-direction="column">
          <Link
            color="--light"
            font="--lead"
            sm-margin="0px 0px 20px 0px"
            sm-width="100%"
            hover-color="--dark"
            border-width="2px"
            border-style="solid"
            href="/contact"
            text-decoration-line="initial"
            border-radius="75px"
            background="--color-dark"
            border-color="--color-dark"
            padding="10px 53px 10px 53px"
            margin="0px 24px 0px 0px"
            transition="background-color 0.3s linear 0s"
            hover-background="rgba(4, 8, 12, 0)"
            sm-text-align="center"
          >
            Contactez-nous
          </Link>
        </Box>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        min-height="600px"
        sm-min-height="auto"
        display="flex"
        lg-padding="45px 0 45px 0"
        quarkly-title="Advantages/Features-18"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          grid-gap="5%"
          max-width="1200px"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          align-items="center"
          lg-width="100%"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
          lg-justify-content="center"
          sm-justify-content="flex-start"
        >
          <Text
            margin="4px 0px 12px 0px"
            font="--headline2"
            color="--darkL2"
            width="100%"
            md-width="100%"
            text-align="center"
            sm-text-align="left"
          >
            Pourquoi rouler avec nous?
          </Text>
          <Box
            display="grid"
            width="100%"
            grid-template-columns="repeat(2, 1fr)"
            grid-gap="24px"
            margin="60px 0px 0px 0px"
            max-width="1000px"
            md-grid-template-columns="1fr"
            md-grid-gap="6px"
            sm-grid-gap="32px"
          >
            <Box
              padding="24px 24px 48px 24px"
              border-radius="8px"
              display="flex"
              sm-padding="0px 0px 0px 0px"
              md-padding="24px 24px 24px 24px"
              sm-flex-direction="column"
            >
              <Icon
                category="io"
                icon={IoIosCheckmarkCircle}
                size="42px"
                color="--light"
                margin="0px 24px 0px 0px"
                background="--color-primary"
                padding="8px 8px 8px 8px"
                border-radius="8px"
                sm-margin="0px 0px 12px 0px"
              />
              <Box>
                <Text
                  font="--lead"
                  color="--darkL2"
                  margin="0px 0px 0px 0px"
                  letter-spacing="0.3px"
                  sm-margin="4px 0px 0px 0px"
                >
                  <Strong>Des locations flexibles :</Strong>
                </Text>
                <Text
                  margin="16px 0px 0px 0px"
                  font="--base"
                  color="--darkL1"
                  sm-margin="6px 0px 0px 0px"
                >
                  Que vous ayez besoin d'un véhicule pour une journée, une
                  semaine ou plus longtemps, nous vous proposons des plans de
                  location flexibles adaptés à votre trajet.
                </Text>
              </Box>
            </Box>
            <Box
              padding="24px 24px 48px 24px"
              border-radius="8px"
              display="flex"
              sm-padding="0px 0px 0px 0px"
              md-padding="24px 24px 24px 24px"
              sm-flex-direction="column"
            >
              <Icon
                category="io"
                icon={IoIosCheckmarkCircle}
                size="42px"
                color="--light"
                margin="0px 24px 0px 0px"
                background="--color-primary"
                padding="8px 8px 8px 8px"
                border-radius="8px"
                sm-margin="0px 0px 12px 0px"
              />
              <Box>
                <Text
                  font="--lead"
                  color="--darkL2"
                  margin="0px 0px 0px 0px"
                  letter-spacing="0.3px"
                  sm-margin="4px 0px 0px 0px"
                >
                  <Strong>Des prix compétitifs :</Strong>
                </Text>
                <Text
                  margin="16px 0px 0px 0px"
                  font="--base"
                  color="--darkL1"
                  sm-margin="6px 0px 0px 0px"
                >
                  Profitez des meilleurs tarifs du marché sans faire de
                  compromis sur la qualité ou le service. Plus d'aventure, moins
                  de dépenses.
                </Text>
              </Box>
            </Box>
            <Box
              padding="24px 24px 48px 24px"
              border-radius="8px"
              display="flex"
              sm-padding="0px 0px 0px 0px"
              md-padding="24px 24px 24px 24px"
              sm-flex-direction="column"
            >
              <Icon
                category="io"
                icon={IoIosCheckmarkCircle}
                size="42px"
                color="--light"
                margin="0px 24px 0px 0px"
                background="--color-primary"
                padding="8px 8px 8px 8px"
                border-radius="8px"
                sm-margin="0px 0px 12px 0px"
              />
              <Box>
                <Text
                  font="--lead"
                  color="--darkL2"
                  margin="0px 0px 0px 0px"
                  letter-spacing="0.3px"
                  sm-margin="4px 0px 0px 0px"
                >
                  <Strong>Des itinéraires personnalisés :</Strong>
                </Text>
                <Text
                  margin="16px 0px 0px 0px"
                  font="--base"
                  color="--darkL1"
                  sm-margin="6px 0px 0px 0px"
                >
                  Accédez gratuitement à nos itinéraires personnalisés.
                  Découvrez les joyaux cachés et les chemins les moins
                  fréquentés, à votre rythme.
                </Text>
              </Box>
            </Box>
            <Box
              padding="24px 24px 48px 24px"
              border-radius="8px"
              display="flex"
              sm-padding="0px 0px 0px 0px"
              md-padding="24px 24px 24px 24px"
              sm-flex-direction="column"
            >
              <Icon
                category="io"
                icon={IoIosCheckmarkCircle}
                size="42px"
                color="--light"
                margin="0px 24px 0px 0px"
                background="--color-primary"
                padding="8px 8px 8px 8px"
                border-radius="8px"
                sm-margin="0px 0px 12px 0px"
              />
              <Box>
                <Text
                  font="--lead"
                  color="--darkL2"
                  margin="0px 0px 0px 0px"
                  letter-spacing="0.3px"
                  sm-margin="4px 0px 0px 0px"
                >
                  <Strong>Une qualité inégalée:</Strong>
                </Text>
                <Text
                  margin="16px 0px 0px 0px"
                  font="--base"
                  color="--darkL1"
                  sm-margin="6px 0px 0px 0px"
                >
                  Chaque vélo de notre flotte est soumis à des contrôles
                  rigoureux et à un entretien régulier pour garantir votre
                  sécurité et votre confort.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-4"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          width="65%"
          justify-content="center"
          align-items="flex-start"
          margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          display="flex"
          lg-width="100%"
          padding="16px 16px 16px 16px"
          sm-margin="0px 0px 30px 0px"
        >
          <Box
            width="100%"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 125% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              bottom={0}
              min-height="100%"
              top={0}
              left={0}
              position="absolute"
              display="block"
              width="100%"
              right={0}
              src="https://sovixel.com/img/2.jpg"
              object-fit="cover"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          align-items="flex-start"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
          lg-order="1"
          width="35%"
          flex-direction="column"
          lg-align-items="center"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            padding="0px 0px 65% 0px"
            width="100%"
            height="auto"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              right={0}
              bottom="0px"
              object-fit="cover"
              position="absolute"
              display="block"
              width="100%"
              top="auto"
              min-height="100%"
              src="https://sovixel.com/img/3.jpg"
            />
          </Box>
          <Text
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            margin="24px 0px 48px 0px"
            md-text-align="left"
          >
            Ne vous contentez pas de rêver d'aventures, vivez-les. Sovixel
            Rentals - Votre première étape dans le voyage de votre vie.
          </Text>
          <Box
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            width="100%"
            padding="0px 0px 65% 0px"
          >
            <Image
              display="block"
              width="100%"
              top="auto"
              bottom="0px"
              min-height="100%"
              src="https://sovixel.com/img/4.jpg"
              object-fit="cover"
              position="absolute"
              left={0}
              right={0}
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
